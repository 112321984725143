import config from "../../_helpers/config";
import { apiFetcher } from "./apiFetcher";
import _ from "lodash";

export const productsService = {
  getProducts,
  getProductById,
};

function getProducts(query) {
  const url = `/products/query`

  let body = {
    query: query,
    includeVariants: true
  }

  if(!query.paging?.limit){
    body.query.paging = {
      ...body.query.paging,
      limit: config.PRODUCTS_PER_PAGE
    }
  }

  return apiFetcher(url, { method: "POST", body: JSON.stringify(body) }, 1, true);
}

function getProductById(productId) {
  const url = `/products/${productId}`

  return apiFetcher(url, {
    method: "GET"
  })
}